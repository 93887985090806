.container{
    background: rgba(11, 4, 51, 0.767);
    background-image: url('https://desktop.github.com/images/star-bg.svg');
    width: 100%;
}
.section{
    background: rgb(145, 144, 150);
    border: solid 2px var(--colorSecundario3);
    margin: auto;
    width: 80%;
    height: 400px;
    border-radius: 5rem;
padding: 5px;
    
}
.section2{
    background: rgb(145, 144, 150);
    border: solid 2px var(--colorSecundario3);
    margin: auto;
    width: 80%;
    height: 800px;
    border-radius: 5rem;
padding: 5px;
    
}

.section h1{
    font-family: var(--fontTitle);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contentText{
    color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  /* border: solid 2px blue; */
}

.descrp{
    margin-left: 30px;
    /* border: solid 2px yellow; */
    width: 50%;
}
.descrp p{
    font-weight: 400;
}

.descrp2{
    margin-right: 50px;
    /* border: solid 2px red; */
    width: 25%;
}

.descrp2 ul li{
    font-size: 2rem;
    list-style: circle;
}
.mvv{
    border-radius: 5rem;
}
.ilusimg{
    width: 200px;
    height: 200px;
}

.immg{
    width: 100%;
}

@supports(object-fit: cover){
    .immg{
      object-fit: cover;
      object-position: center center;
    }
}