@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@360&display=swap);
body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Document
   ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */

/* Variables */
:root {
  /* Typografia */
  --sizeText: 18px;
  --fontTitle: "Ultra", serif;

  /* Colors */
  --colorPrimario1: #ed008c;
  --colorPrimario2: #662d91;
  --colorPrimario3: #7f8284;
  --colorPrimario4: #280048;

  --colorSecundario1: #6fc8bf;
  --colorSecundario2: #1d56a6;
  --colorSecundario3: #faab65;
  --colorSecundario4: #ed1976;
}
/* Body */

body {
  font-family: Arial, Helvetica, sans-serif;
  background-image: url("https://desktop.github.com/images/star-bg.svg");
  background: var(--Dark-Gunmetal);
}
/**
 * Render the `main` element consistently in IE.
 */

main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
  max-width: 100%;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

.containerLogin {
  background-color: rgb(53, 40, 40);
  position: absolute;
  display: flex;
  width: 25%;
  height: 50%;
  border-radius: 5%;
}
.container_item {
  align-items: center;
}

/* Navigation Bar */
nav {
  width: 100%;
  position: relative;
  box-shadow: 0 0 30px 0 rgb(75, 65, 65);
  z-index: 1;
}
/* Desing Default */
.nav1 {
  /* background: linear-gradient(90deg, rgba(127,130,132,1) 20%, rgba(237,0,140,1) 40%, rgba(102,45,145,1) 60%, rgba(40,0,72,1) 80%); */
  background: linear-gradient(
    90deg,
    rgba(237, 0, 140, 1) 30%,
    rgba(102, 45, 145, 1) 60%,
    rgba(40, 0, 72, 1) 90%
  );
  height: 80px;
}
/* Second Desing */
.nav2 {
  background: rgb(2, 1, 14);
  height: 100px;
}
/* Container Bar */
.nav__container {
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  overflow: hidden;
  /* border: solid 2px  yellow; */
}
/* Logo */
.nav__logo {
  /* border: solid 2px  red; */
  margin: 20px;
  width: 150px;
  height: 60px;
}

.logo {
  width: 100%;
  height: 100%;
}

/* Links */
.nav__links a {
  /* border: solid 2px  blue; */
  display: inline-block;
  padding: 5px 0;
  margin-right: 20px;
  font-size: var(--sizeText);
  font-weight: 300;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  color: white;
}
/* Effects Links */
.nav__links a:hover {
  color: var(--colorSecundario3);
  border-bottom: 3px solid var(--colorSecundario3);
  transition: 0.6s;
}

.container{
    background: rgba(11, 4, 51, 0.767);
    background-image: url('https://desktop.github.com/images/star-bg.svg');
    width: 100%;
}
.section{
    background: rgb(145, 144, 150);
    border: solid 2px var(--colorSecundario3);
    margin: auto;
    width: 80%;
    height: 400px;
    border-radius: 5rem;
padding: 5px;
    
}
.section2{
    background: rgb(145, 144, 150);
    border: solid 2px var(--colorSecundario3);
    margin: auto;
    width: 80%;
    height: 800px;
    border-radius: 5rem;
padding: 5px;
    
}

.section h1{
    font-family: var(--fontTitle);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contentText{
    color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  /* border: solid 2px blue; */
}

.descrp{
    margin-left: 30px;
    /* border: solid 2px yellow; */
    width: 50%;
}
.descrp p{
    font-weight: 400;
}

.descrp2{
    margin-right: 50px;
    /* border: solid 2px red; */
    width: 25%;
}

.descrp2 ul li{
    font-size: 2rem;
    list-style: circle;
}
.mvv{
    border-radius: 5rem;
}
.ilusimg{
    width: 200px;
    height: 200px;
}

.immg{
    width: 100%;
}

@supports(object-fit: cover){
    .immg{
      object-fit: cover;
      object-position: center center;
    }
}
.signup-container {
  background-image: url(/static/media/singup.19407dc3.jpeg);
  background-size: 215vh;
  background-position: bottom;
  margin: auto;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  height: 200px;
  border-radius: 2rem;
}

.input {
  width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5rem;
}

.btn {
  font-weight: 800;
  margin-top: 5px;
  border-radius: 5rem;
}

