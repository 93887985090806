/* Navigation Bar */
nav {
  width: 100%;
  position: relative;
  box-shadow: 0 0 30px 0 rgb(75, 65, 65);
  z-index: 1;
}
/* Desing Default */
.nav1 {
  /* background: linear-gradient(90deg, rgba(127,130,132,1) 20%, rgba(237,0,140,1) 40%, rgba(102,45,145,1) 60%, rgba(40,0,72,1) 80%); */
  background: linear-gradient(
    90deg,
    rgba(237, 0, 140, 1) 30%,
    rgba(102, 45, 145, 1) 60%,
    rgba(40, 0, 72, 1) 90%
  );
  height: 80px;
}
/* Second Desing */
.nav2 {
  background: rgb(2, 1, 14);
  height: 100px;
}
/* Container Bar */
.nav__container {
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  overflow: hidden;
  /* border: solid 2px  yellow; */
}
/* Logo */
.nav__logo {
  /* border: solid 2px  red; */
  margin: 20px;
  width: 150px;
  height: 60px;
}

.logo {
  width: 100%;
  height: 100%;
}

/* Links */
.nav__links a {
  /* border: solid 2px  blue; */
  display: inline-block;
  padding: 5px 0;
  margin-right: 20px;
  font-size: var(--sizeText);
  font-weight: 300;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  color: white;
}
/* Effects Links */
.nav__links a:hover {
  color: var(--colorSecundario3);
  border-bottom: 3px solid var(--colorSecundario3);
  transition: 0.6s;
}
