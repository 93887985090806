.signup-container {
  background-image: url("assets/singup.jpeg");
  background-size: 215vh;
  background-position: bottom;
  margin: auto;
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  height: 200px;
  border-radius: 2rem;
}

.input {
  width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5rem;
}

.btn {
  font-weight: 800;
  margin-top: 5px;
  border-radius: 5rem;
}
